.benefits {
    padding: 0 0 97px;
    @media (max-width: 992px) {padding-bottom: 52px;}
    &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-flow: row wrap;
        }
        & .bnf {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 992px) {
                width: 100%;
                max-width: 305px;
                &:not(:last-child) {margin-bottom: 20px;}
            }
            @media (max-width: 600px) {
                max-width: 260px;
                & p { 
                    & br {display: none;}
                }
            }
            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 23px;
                width: 100px;
                min-width: 100px;
                height: 100px;
                background-color: #f8f8f8;
                border-radius: 50%;
                & img {max-width: 46px;}
                & img.bnf-icon-4 {max-width: 33px;}
                @media (max-width: 1170px)  {
                    margin-right: 10px;
                }
            }
            & p {
                max-width: 140px;
                font-family: 'Gilroy Regular';
                font-size: 16px;
                line-height: 21px;
                color: #363636;
                @media (max-width: 1170px)  {
                    font-size: 14px;
                    line-height: 18px;
                }
                @media (max-width: 992px)  {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
}