.sections {
    padding-bottom: 100px;
    @media (max-width: 992px) {padding-bottom: 45px;}
    &-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }
    &-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 563px;
        padding-right: 30px;
        background-image: url('../images/gray.jpg');
        background-repeat: no-repeat;
        background-size: 100% 294px;
        background-position: center;
        @media (max-width: 992px) {
            padding-right: 10px;
            background-size: 100% 250px;
            background-position: top center;
        }
        @media (max-width: 767px) {
            padding-right: 0;
            background-size: 100% 174px;
        }
        &:not(:last-child) {
            margin-right: 10px;
            @media (max-width: 992px) {margin:  0 0 50px;}
            @media (max-width: 767px) {margin:  0 0 15px;}
        }
        &.active .sections-title {color: #8dc63f;}
    }
    &-cnt {
        padding: 134px 15px 20px 50px;
        @media (max-width: 1600px) {padding: 50px 15px 20px 15px;}
        @media (max-width: 992px) {padding: 70px 15px 20px 25px;}
        @media (max-width: 767px) {padding: 23px 0 15px 28px;}
    }
    &-title {
        display: block;
        margin-bottom: 21px;
        font-family: 'Gilroy Black';
        font-size: 26px;
        line-height: 26px;
        text-transform: uppercase;
        color: #4c4c4c;
        transition: 0.3s ease;
        @media (max-width: 1270px) {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 18px;
        }
        @media (max-width: 767px) {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 16px;
        }
        &:hover {color: #8dc63f;}
    }
    &-links {
        & a {
            display: inline-block;
            margin-bottom: 22px;
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 14px;
            color: #4c4c4c;
            transition: 0.3s ease;
            @media (max-width: 1270px) {
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 22px;
            }
            &:not(:last-child) {
                margin-right: 20px;
                @media (max-width: 1270px) {margin-right: 10px;}
                @media (max-width: 600px) {margin-right: 0;}
            }
            &:hover,
            &.active {color: #8dc63f;}
            @media (max-width: 1270px) {margin-bottom: 10px;}
            @media (max-width: 600px) {display: block;}
        }
    }
    &-img {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: 300px;
        transition: 0.2s linear;
        & img {
            max-width: 100%;
            @media (max-width: 767px) {max-height: 210px;}
        }
        @media (max-width: 1600px) {max-width: 200px;}
        @media (max-width: 992px) {max-width: 300px;}
        @media (max-width: 767px) {
            justify-content: center;
            width: 100%;
            max-width: 155px;
        }
        @media (max-width: 359px) {max-width: 115px;}
    }
    & .sections-item-1 {
        & .sections-cnt {
            width: 100%;
            max-width: 275px;
            @media (max-width: 1600px) {max-width: 235px;}
            @media (max-width: 1250px) {max-width: 180px;}
            @media (max-width: 992px) {max-width: 300px;}
        }
        & .sections-img {
            position: relative;
            right: 0;
            top: 39px;
            max-width: 238px;
            @media (max-width: 1600px) {
                top: 50px;
                max-width: 150px;
            }
            @media (max-width: 1250px) {top: 35px;}
            @media (max-width: 992px) {max-width: 140px;}
            @media (max-width: 600px) {
                top: 8px;
                max-width: 142px;
            }
            @media (max-width: 359px) {padding-left: 15px;}
            & img {width: 100%;}
        }
    }
    & .sections-item-2 {
        & .sections-cnt {
            width: 100%;
            max-width: 355px;
            @media (max-width: 1250px) {max-width: 210px;}
            @media (max-width: 992px) {max-width: 300px;}
            @media (max-width: 600px) {
                width: 100%;
                max-width: 170px !important;
                padding: 35px 0 15px 28px;
            }
            @media (max-width: 359px) {
                padding-left: 15px;
                max-width: 160px !important;
            }
        }
        & .sections-img {
            position: relative;
            right: 36px;
            top: 39px;
            width: 100%;
            max-width: 164px;
            @media (max-width: 1600px) {
                right: 0;
                width: 100%;
                max-width: 130px;
            }
            @media (max-width: 1600px) {max-width: 90px;}
            @media (max-width: 992px) {
                top: 0;
                max-width: 130px;
            }
            @media (max-width: 600px) {
                right: 22px;
                max-width: 101px;
            }
            & img {width: 100%;}
        }
    }
    & .sections-item-3 {
        & .sections-cnt {
            width: 100%;
            max-width: 227px;
            @media (max-width: 1250px) {max-width: 165px;}
            @media (max-width: 992px) {max-width: 300px;}
            @media (max-width: 600px) {padding: 32px 0 15px 28px;}
            @media (max-width: 359px) {padding-left: 15px;}
        }
        & .sections-img {
            position: relative;
            right: 0;
            top: 46px;
            width: 100%;
            max-width: 296px;
            @media (max-width: 1600px) {
                top: 20px;
                width: 100%;
                max-width: 200px;
            }
            @media (max-width: 600px) {
                top: 3px;
                max-width: 166px;
            }
            & img {width: 100%;}
        }
    }
    & .sections-item-4 {
        padding-right: 0;
        min-height: 300px;
        @media (max-width: 600px) {min-height: 0;}
        & .sections-cnt {
            width: 100%;
            max-width: 323px;
            padding: 113px 15px 20px 52px;
            @media (max-width: 1600px) {padding: 50px 15px 20px 15px;}
            @media (max-width: 992px) {max-width: 300px;}
            @media (max-width: 600px) {padding: 32px 0 15px 28px;}
            @media (max-width: 359px) {padding-left: 15px;}
        }
        & .sections-img {
            position: relative;
            right: 0;
            top: 69px;
            width: 100%;
            max-width: 258px;
            @media (max-width: 1600px) {
                top: 20px;
                width: 100%;
                max-width: 200px;
            }
            @media (max-width: 600px) {
                top: 20px;
                max-width: 115px;
            }
            & img {width: 100%;}
        }
    }
    & .sections-item-5 {
        padding-right: 0;
        & .sections-cnt {
            width: 100%;
            max-width: 260px;
            padding: 154px 15px 20px 55px;
            @media (max-width: 1250px) {
                width: 100%;
                max-width: 240px;
                padding: 15px 15px 15px 52px;
                height: 100%;
                display: flex;
                align-items: center;
            }
            @media (max-width: 1600px) {padding: 50px 15px 20px 15px;}
            @media (max-width: 992px) {max-width: 300px;}
            @media (max-width: 600px) {padding: 32px 0 15px 28px;}
            @media (max-width: 359px) {padding-left: 15px;}
        }
        & .sections-img {
            position: relative;
            right: 94px;
            top: 25px;
            width: 100%;
            max-width: 130px;
            @media (max-width: 600px) {right: 0;}
            & img {width: 100%;}
        }
    }
    & .sections-item-6 {
        padding-right: 0;
        min-height: 300px;
        @media (max-width: 600px) {min-height: 0;}
        & .sections-cnt {
            width: 100%;
            max-width: 305px;
            padding: 153px 15px 20px 61px;
            @media (max-width: 1250px) {
                width: 100%;
                max-width: 240px;
                padding: 15px 15px 15px 52px;
                height: 100%;
                display: flex;
                align-items: center;
            }
            @media (max-width: 1600px) {padding: 50px 15px 20px 15px;}
            @media (max-width: 992px) {max-width: 300px;}
            @media (max-width: 600px) {padding: 32px 0 15px 28px;}
            @media (max-width: 359px) {padding-left: 15px;}
        }
        & .sections-img {
            position: relative;
            right: 24px;
            top: 79px;
            width: 100%;
            max-width: 250px;
            @media (max-width: 1600px) {
                top: 20px;
                width: 100%;
                max-width: 200px;
            }
            @media (max-width: 600px) {
                top: 10px;
                max-width: 110px;
            }
            & img {width: 100%;}
        }
    }
    @media (min-width: 992px)  {
        & .sections-item:hover .sections-img,
        & .sections-item.active .sections-img {transform: translateY(-28px);}
    }
    
}