.price {
    padding-bottom: 85px;
    & .sections {
        margin-top: -48px;
        padding-bottom: 50px;
    }
    & .sections-list {
        flex-flow: row wrap;
        justify-content: space-around;
        @media (max-width: 1758px) {justify-content: space-around;}
        @media (max-width: 992px) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    & .sections-item {
        margin-bottom: 51px;
        @media (max-width: 1200px) {max-width: 420px;}
        @media (max-width: 992px) {max-width: 563px;}
        @media (max-width: 992px) {max-width: 563px;}
        &.sections-item-1 {
            @media (max-width: 600px) {background-size: cover !important;}
            @media (max-width: 600px) {top: 30px;}
        }
    }

    .sections-links a {margin-bottom: 9px;}

    & .sections-item-1{
        & .sections-links a:not(:last-child) {margin-right: 8px;}
    }
    &-info {
        padding-bottom: 65px;
        h5 {
            margin-bottom: 25px;
            font-family: 'Gilroy Bold';
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
            color: #4c4c4c;
            @media (max-width: 992px)  {
                font-size: 16px;
                line-height: 16px;
            }
        }
        p {
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 21px;
            @media (max-width: 992px)  {
                font-size: 14px;
                line-height: 21px;
            }
            color: #898989;
            &:not(:last-child) {margin-bottom: 21px;}
        }
    }
}


.table {
    width: 100%;
    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        & .table-mob-img {
            display: none;
            @media (max-width: 992px) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 30px auto 0;
                width: 50px;
            }
        }
    }
    @media (max-width: 992px) {
        width: 100%;
        overflow-x: scroll;
    }
    & table {
        width: 100%;
        @media (max-width: 992px) {
            min-width: 960px;
        }
        & th {
            height: 60px;
            padding: 0px 11px 10px;
            text-align: center;
            vertical-align: middle;
            font-family: 'Gilroy Bold';
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            color: #4c4c4c;
            &:first-child {
                text-align: left;
                padding-left: 20px;
            }
        }
        & td {
            height: 54px;
            padding: 10px 12px 10px;
            text-align: center;
            vertical-align: middle;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 16px;
            color: #898989;
            transition: 0.3s ease;
            &:first-child {
                text-align: left;
                padding-left: 23px;
            }
            a {
                font-family: 'Gilroy Regular';
                font-size: 14px;
                line-height: 14px;
                text-transform: uppercase;
                color: #4c4c4c;
                transition: 0.3s ease;
            }
        }
        & tr {
            cursor: pointer;
            &:nth-child(even) {background-color: #f8f8f8;}
            @media (min-width: 993px) {
                &:not(:first-child):hover {background-color: #8dc63f;}
                &:hover a,
                &:hover td{color: #ffffff;}
            }
        }
    }
}