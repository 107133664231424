.card {
    padding-bottom: 65px;
    & .article {
        & h5 {
            font-family: 'Gilroy Bold';
            margin-bottom: 29px;
            font-size: 21px;
            line-height: 26px;
        }
        & iframe {
            margin: 0 0 34px;
            max-width: 639px;
            min-height: 360px;
            @media (max-width: 767px) {
                min-height: 250px;
            }
            @media (max-width: 75px) {
                min-height: 0;
            }
        }
        & h4 {
            font-family: 'Gilroy Bold';
            margin-bottom: 40px;
            padding-top: 42px;
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
            color: #737373;
        }
    }
    & .table-wrap {
        & h4 {
            width: 100%;
            font-family: 'Gilroy Bold';
            margin-bottom: 40px;
            padding-top: 67px;
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
            color: #737373;
            @media (max-width: 767px) {
                margin-bottom: 26px;
                padding-top: 49px;
            }
        }
    }
    & .how {
        padding-bottom: 0;
        .how-list {margin-bottom: 41px;}
    }
    & .tabs-section {
        justify-content: flex-end;
        padding-bottom: 31px;
        @media (max-width: 767px) {
            justify-content: flex-start;
            padding-bottom: 37px;        
        }
        & a {
            font-size: 18px;
            line-height: 22px;
            @media (max-width: 767px) {
                font-size: 12px;
                line-height: 12px;
                padding-bottom: 7px;
                & span {display: none;}
            }
            &:not(:last-child) {
                margin-right: 134px;
                @media (max-width: 767px) {
                    margin-right: 20px;
                }
            }
        }
    }
    & .characteristics {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media (max-width: 992px) {flex-direction: column;}
        & .files-items-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            & .audio-item:not(:last-child) {
                margin-right: 20px;
                @media (max-width: 359px) {margin-right: 5px;}
            }
            .audio-item-cnt {
                min-height: 189px;
                padding: 28px 35px 55px 24px;
                @media (max-width: 767px) {
                    min-height: 147px;
                    padding: 26px 33px 48px 6px;
                }
            }
            .audio-item {
                position: relative;
                width: 100%;
                max-width: 206px;
                min-width: 206px;
                min-height: 196px;
                padding: 15px 10px 0 0;
                @media (max-width: 767px) {
                    max-width: 140px;
                    min-width: 140px;
                    min-height: 140px;
                    & img {max-width: 43px;}
                    & .audio-title {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                & .audio-listen {
                    bottom: 33px;
                    @media (max-width: 767px)  {
                        bottom: 22px;
                        text-align: left;
                        padding-left: 12px;
                    }
                }
            }
        }
        &-table {
            width: 100%;
            max-width: 488px;
            padding-right: 10px;
            padding-top: 48px;
            @media (max-width: 992px) {
                padding: 0 0 30px;
            } 
            &-row {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:not(:last-child) {margin-bottom: 25px;}
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 10px;
                    border-bottom: 1px dashed #737373;
                }
            }
            & p {padding-right: 5px;}
            & span {padding-left: 5px;}
            & p,
            & span {
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                font-family: 'Gilroy Bold';
                font-size: 16px;
                line-height: 16px;
                color: #737373;
                @media (max-width: 992px) {
                    font-size: 14px;
                    line-height: 14px;
                }
                @media (max-width: 359px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
            & span {
                font-family: 'Gilroy Regular';
                @media (max-width: 992px) {
                    font-size: 14px;
                    line-height: 14px;
                    min-width: 0;
                }
                @media (max-width: 359px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
            & .filter-item-info {
                pointer-events: fill;
                margin-left: 5px;
                & svg {
                    width: 14px;
                    height: 14px;
                    & path {transition: 0.3s ease;}
                    &:hover path,
                    &.active path {fill: #8dc63f;}
                }
                &-hidden {
                    pointer-events: none;
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 50%;
                    padding: 12px 10px;
                    white-space: initial;
                    left: calc(100% + 10px);
                    transform: translateY(-50%) scale(0);
                    width: 100%;
                    max-width: 281px;
                    min-width: 281px;
                    padding: 12px 10px;
                    overflow: hidden;
                    font-family: 'Gilroy Medium';
                    font-size: 14px;
                    line-height: 21px;
                    color: #c2c2c2;
                    background-color: #ffffff;
                    box-shadow: 0 0 3px lightgray;
                    transition: 0.3s ease;
                    & img {
                        pointer-events: fill;
                        display: none;
                        position: absolute;
                        cursor: pointer;
                        top: 5px;
                        right: 5px;
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        max-width: 20px;
                        min-height: 20px;
                        @media (max-width: 992px)  {display: block;}
                    }
                    @media (max-width: 992px) {
                        padding-top: 30px;
                        left: 0;
                        transform: translateY(0) scale(0);
                        top: 100%;
                    }
                    @media (max-width: 767px) {
                        padding-top: 30px;
                        left: 0;
                    }
                }
                &.active .filter-item-info-hidden {
                    transform: translateY(0) scale(1);
                }
                @media (min-width: 993px) {
                    &:hover .filter-item-info-hidden{
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
        &-info {
            min-width: 160px;
            text-align: left;
        }
    }
    &-item {
        background-color: #f8f8f8;
        margin-bottom: 40px;
        @media (max-width: 1250px) {overflow: hidden;}
        & .article-share {
            top: 48px;
            right: 7px;
            @media (max-width: 1250px) {width: 100%;}
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 37px;
            @media (max-width: 650px)  {
                flex-flow: row wrap;
                padding-bottom: 15px;
            }
            & .title {
                margin-bottom: 0;
                @media (max-width: 650px)  {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
        }
        &-add {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 25px;
            padding-top: 14px;
            cursor: pointer;
            @media (max-width: 650px)  {
                &:nth-child(2) {padding-left: 0;}
            }
            & span {
                font-family: 'Gilroy Regular';
                font-size: 14px;
                line-height: 14px;
                text-transform: uppercase;
                color: #737373;
                transition: 0.3s ease;
            }
            & svg {
                &:not(:last-child) {margin-right: 13px;}
                & path {fill: #737373;}
            }
            &.active,
            &:hover  {
                span {color: #8dc63f;}
                & svg {
                    & path {fill: #8dc63f;}
                }
            }
        }
        &-row {
            position: relative;
            display: flex;
            justify-content: flex-start;
            padding: 50px 97px 21px 0;
            min-height: 322px;
            @media (max-width: 1250px) {
                flex-flow: row wrap;
                padding: 50px 0;
            }
            @media (max-width: 992px) {justify-content: center;}
        }
    }
    &-img{
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: 0.3s ease;
        transform: translateY(43px);
        @media (max-width: 1250px) {transform: translateY(0);}
        & img {
            max-width: 100%;
            max-height: 350px;
        }
        @media (min-width: 1251px) {&:hover  {transform: translateY(35px);}}
        
        &-wrap {
            position: relative;
            width: 100%;
            max-width: 380px;
            min-height: 250px;
            padding-left: 61px;
            padding-right: 20px;
            @media (max-width: 1250px) {
                max-width: 300px;
                padding-left: 75px;
                padding-right: 10px;
            }
            @media (max-width: 992px) {max-width: 380px;}
            @media (max-width: 650px) {
                min-height: 217px;
            }
        }
        &-bg {
            position: absolute;
            bottom: -100px;
            left: 0;
            z-index: 0;
            &.card-img-bg-mob {display: none;}
            @media (max-width: 1250px) {
                top: -125px;
                left: 0px;
            }
            @media (max-width: 600px) {
                top: -183px;
                left: 43px;
            }
            @media (max-width: 1250px) {
                &:not(.card-img-bg-mob) {display: none;}
                &.card-img-bg-mob {display: block;}
            }
        }
    }
    &-icons-list {
        position: absolute;
        bottom: 0px;
        left: 21px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        @media (max-width: 1250px) {bottom: 0px;}
        @media (max-width: 650px) {
            left: 14px;
            bottom: -30px;
        }
        .card-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            &:not(:last-child) {margin-bottom: 20px;}
            &-1 img {width: 33px;}
            &-2 img {width: 38px;}
            &-3 img {width: 35px;}
            &-4 img {width: 34px;}
            &-5 img {width: 28px;}
            & span {
                position: absolute;
                top: -6px;
                right: -6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                font-family: 'Gilroy Regular';
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #8dc63f;
                background-color: #ffffff;
                border-radius: 50%;
                box-shadow: 0 0 5px lightgrey;
            }
        }
    }
    &-buy-info {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 181px;
        padding-right: 10px;
        margin-right: 77px;
        @media (max-width: 1250px) {
            max-width: 140px;
            margin-right: 30px;
            padding-right: 0;
            padding-top: 15px;
        }
        @media (max-width: 1250px) {max-width: 200px;}
        @media (max-width: 650px) {
            display: flex;
            max-width: 100%;
            justify-content: center;
            padding-top: 68px;
        }
        @media (max-width: 400px) {
            justify-content: flex-start;
        }
    }
    & .card-buy-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        & .btn {
            margin-bottom: 20px;
            max-width: 139px;
            height: 45px;
            font-size: 18px;
            line-height: 45px;
            @media (max-width: 650px) {
                margin-bottom: 15px;
                max-width: 118px;
                height: 38px;
                font-size: 15px;
                line-height: 38px;
            }
        }
    }
    &-price{
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: 30px;
        padding-top: 30px;
        @media (max-width: 650px) {
            order: 2;
            padding: 0;
            margin-left: 16px;
        }
        @media (max-width: 359px) {margin-left: 10px;}
        &-old {
            display: block;
            padding-right: 40px;
            font-family: 'Gilroy Black';
            font-size: 20px;
            line-height: 20px;
            text-transform: uppercase;
            text-align: right;
            color: #898989;
            @media (max-width: 1250px) {
                font-size: 14px;
                line-height: 14px;
                padding-right: 34px;
            }
            @media (max-width: 359px) {
                font-size: 12px;
                line-height: 12px;
                padding-right: 34px;
            }
            span {
                font-size: 14px;
                line-height: 14px;
                @media (max-width: 1250px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
        &-new {
            display: block;
            font-family: 'Gilroy Black';
            font-size: 32px;
            line-height: 32px;
            text-transform: uppercase;
            text-align: right;
            color: #4c4c4c;
            @media (max-width: 1250px) {
                font-size: 24px;
                line-height: 24px;
            }
            @media (max-width: 359px) {
                font-size: 19px;
                line-height: 19px;
            }
            span {
                font-size: 16px;
                line-height: 16px;
                @media (max-width: 1250px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
    }
    &-triggers {
        position: absolute;
        bottom: 63px;
        right: -40px;
        width: 100%;
        max-width: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        @media (max-width: 650px) {
            bottom: 62px;
            right: -42px;
        }
        & .card-trigger {
            display: inline-block;
            height: 21px;
            padding: 0 8px;
            font-family: 'Gilroy SemiBold';
            font-size: 9px;
            line-height: 21px;
            vertical-align: middle;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            &:not(:last-child) {margin-bottom: 6px;}
            &.green {background-color: #8dc63f;}
            &.orange {background-color: #f7941c;}
        }
        & .one-click-trigger {
            @media (max-width: 650px) {
                font-size: 15px;
                line-height: 15px;
            }
            @media (max-width: 359px) {
                font-size: 13px;
                line-height: 13px;
            }
        }
    }
    &-info {
        position: relative;
        // z-index: 1;
        padding-bottom: 40px;
        width: 100%;
        max-width: 200px;
        margin-right: 12px;
        padding-top: 30px;
        @media (max-width: 1250px)  {max-width: 170px;}
        @media (max-width: 992px)  {max-width: 350px;}
        @media (max-width: 830px)  {max-width: 200px;}
        @media (max-width: 650px)  {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            max-width: 100%;
            padding-bottom: 30px;
            padding-top: 32px;
        }
        &-row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            &:not(:last-child) {
                margin-bottom: 28px;
                @media (max-width: 650px) {
                    margin: 0 15px 0 0;
                }
            }
            &:last-child {
                & .filter-item-info-hidden {
                    left: auto;
                    right: -47px;
                }
            }
            & p {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;   
                padding-bottom: 17px;
                font-family: 'Gilroy Regular';
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                color: #4c4c4c;
                @media (max-width: 650px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
            & span {
                display: block;
                font-family: 'Gilroy Medium';
                font-size: 16px;
                line-height: 16px;
                color: #959595;
                @media (max-width: 650px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
            & .filter-item-info {
                pointer-events: fill;
                padding-left: 10px;
                & svg {
                    width: 14px;
                    height: 14px;
                    & path {transition: 0.3s ease;}
                    &:hover path,
                    &.active path {fill: #8dc63f;}
                }
                &-hidden {
                    pointer-events: none;
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 50%;
                    padding: 12px 10px;
                    white-space: initial;
                    left: calc(100% + 10px);
                    transform: translateY(-50%) scale(0);
                    width: 100%;
                    text-transform: initial;                
                    max-width: 281px;
                    min-width: 281px;
                    padding: 12px 10px;
                    overflow: hidden;
                    font-family: 'Gilroy Medium';
                    font-size: 14px;
                    line-height: 21px;
                    color: #c2c2c2;
                    background-color: #ffffff;
                    box-shadow: 0 0 3px lightgray;
                    transition: 0.3s ease;
                    & img {
                        pointer-events: fill;
                        display: none;
                        position: absolute;
                        cursor: pointer;
                        top: 5px;
                        right: 5px;
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        max-width: 20px;
                        min-height: 20px;
                        @media (max-width: 992px)  {display: block;}
                    }
                    @media (max-width: 992px) {
                        padding-top: 30px;
                        left: 0;
                        transform: translateY(0) scale(0);
                        top: 100%;
                    }
                    @media (max-width: 767px) {
                        padding-top: 30px;
                        left: 0;
                    }
                }
                &.active .filter-item-info-hidden {
                    transform: translateY(0) scale(1);
                }
                @media (min-width: 993px) {
                    &:hover .filter-item-info-hidden{
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
    }
    &-text {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 313px;
        @media (max-width: 1250px)  {max-width: 250px;}
        @media (max-width: 992px)  {
            padding-top: 30px;
            max-width: 400px;
        }
        @media (max-width: 650px)  {
            padding-top: 0;
            max-width: 100%;
        }
        p {
            margin-bottom: 19px;
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 19px;
            color: #898989;
            @media (max-width: 1250px)  {
                font-size: 12px;
                line-height: 17px;
            }
            @media (max-width: 650px)  {
                font-size: 14px;
                line-height: 19px;
            }
        }
        & .btn-orange {
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: 'Gilroy Bold';
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            color: #f7941c;
            & img {
                width: 22px;
                margin-right: 12px;
            }
        }
    }
    &-stock {
        background-color: #ffffff;
        margin-bottom: 51px;
        & .wrapper {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            width: 1260px;
            justify-content: flex-start;
            padding: 36px 0 36px 0;
            border: 1px solid #f7941c;
            @media (max-width: 1280px) {
               max-width: calc(100% - 40px);
            }
            @media (max-width: 900px)  {
                flex-flow: row wrap;
            }
            @media (max-width: 650px)  {
                padding: 0 0 35px;
                flex-direction: column;
            }
        }
        &-sticker {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 91px;
            height: 49px;
            margin-right: 19px;
            font-family: 'Gilroy Bold';
            font-size: 22px;
            line-height: 22px;
            text-transform: uppercase;
            color: #ffffff;
            background-color: #f7941c;
            @media (max-width: 700px)  {
                margin: 0 0 20px;
                max-width: 100%;
            }
        }
        & a:not(.card-stock-more) {
            display: inline-block;
            width: 100%;
            max-width: 690px;
            margin-right: 58px;
            font-family: 'Gilroy Regular';
            font-size: 18px;
            line-height: 21px;
            color: #4c4c4c;
            transition: 0.3s ease;
            @media (max-width: 1280px) {
                max-width: 400px;
                margin-right: 20px;
                font-size: 14px;
                line-height: 18px;
            }
            @media (max-width: 900px) {
                max-width: 545px;
                margin: 0;
            }
            @media (max-width: 700px) {
                text-align: center;
                padding: 0 20px;
                width: 100%;
                max-width: 100%;
            }
            &:hover {color: #8dc63f;}
        }
        & .card-stock-more {
            display: inline-block;
            margin-right: 61px;
            font-family: 'Gilroy Bold';
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            color: #f7941c;
            @media (max-width: 1280px) {
                margin-right: 20px;
            }
            @media (max-width: 900px)  {
                max-width: 100%;
                width: 100%;
                padding: 20px 0;
                text-align: center;
                margin: 0;
            }
        }
        &-date {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            @media (max-width: 900px)  {
                justify-content: center;
                width: 100%;
            }
            span {
                font-family: 'Gilroy Regular';
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #4c4c4c;
            }
            p {
                font-family: 'Gilroy Medium';
                font-size: 28px;
                line-height: 28px;
                text-align: center;
                color: #4c4c4c;
                @media (max-width: 1280px) {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .catalog-preview-card {
        padding-bottom: 50px;
        .catalog-item {
            border: none;
            padding: 25px 0 20px 0;
            max-width: 150px;
            &:hover {box-shadow: none !important;}
            .stickers .sticker {
                min-width: 43px;
                height: 23px;
                font-size: 10px;
                line-height: 10px;
            }
            .stickers .sticker-m {
                font-size: 7px;
                line-height: 7px;
            }
            .stickers .sticker-s {
                font-size: 6px;
                line-height: 6px;
            }
            .item-triggers {
                top: 0px;
                right: 0px;
            }
            .stickers {top: 16px;}
            .catalog-item-img {height: 115px;}
            .catalog-item-title {
                margin-bottom: 0px;
                font-size: 18px;
                line-height: 18px;
            }
            p {
                font-size: 14px;
                line-height: 14px;
            }
            .circle-list {display: none;}
            .catalog-item-price {
                flex-direction: column;
                align-items: flex-start;
            }
            .catalog-item-price span {
                font-size: 21px;
                line-height: 21px;
            }
            .catalog-item-price span.old {
                margin-right: 0;
                margin-bottom: 5px;
            }
            .catalog-item-links .btn {display: none;}
            .link-text br {display: none;}
        }
    }
}