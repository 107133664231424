@import "breadcrumbs.scss";
@import "banner.scss";
@import "infobanner.scss";
@import "benefits.scss";
@import "infoblock.scss";
@import "prices.scss";
@import "catalog.scss";
@import "sections.scss";
@import "siteMap.scss";
@import "stocks.scss";
@import "questions.scss";
@import "thanks.scss";
@import "services.scss";
@import "how.scss";
@import "404.scss";
@import "favorites.scss";
@import "contacts.scss";
@import "cart.scss";
@import "comparison.scss";
@import "price.scss";
@import "reviews.scss";
@import "about.scss";
@import "card.scss";