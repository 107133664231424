.checkout {
    padding-bottom: 80px;
    &-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
    &-form {
        width: 100%;
        max-width: 724px;
        margin-right: 15px;
        @media (max-width: 992px) {
            order: 2;
        }
        & form {width: 100%;}
        &-row:not(:last-child) {margin-bottom: 45px;}
        &  input,
        & textarea,
        & .custom-select {
            width: 100%;
            max-width: 100%;
            &:not(:last-child) {margin-bottom: 17px;}
        }
        & p {
            margin-bottom: 28px;
            font-family: 'Gilroy Bold';
            font-size: 21px;
            line-height: 21px;
            text-transform: uppercase;
            color: #626262;
            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 18px;
            }
        }
        & .btn {
            max-width: 174px;
            height: 45px;
            font-size: 16px;
            line-height: 45px;
        }
        & .field {padding-left: 0;}
        &-column {
            width: 100%;
            max-width: 328px;
            padding-top: 10px;
            @media (max-width: 1030px) {
                max-width: 100%;
                padding-top: 0;
                &:not(:last-child) {margin-bottom: 17px;}
            }
            & span {
                display: block;
                padding-bottom: 36px;
                font-family: 'Gilroy Regular';
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                color: #999999;
            }
            .custom-select .select-items div, .custom-select .select-selected {padding-bottom: 9px;}
            .custom-select .select-items div {padding-bottom: 0;}
            .custom-select .select-items div:not(:last-child) {padding-bottom: 9px;}
            .custom-select:last-child .select-items {
                width: 100%;
                max-width: 100%;
            }

            &-wrap {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-flow: row wrap;
            }
        }
        & .custom-select {
            border-bottom: 1px solid #e1e1e1;
            padding: 0;
        }
    }
    &-info {
        width: 100%;
        max-width: 330px;
        padding: 38px 20px 40px 20px;
        background-color: #f8f8f8;
        @media (max-width: 992px) {
            order: 1;
            margin-bottom: 40px;
            padding: 0;
            background: none;
            max-width: 500px;
        }
        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-all {padding-top: 10px;}
            &:not(:last-child) {margin-bottom: 13px;}
            & a,
            & .checkout-all {
                width: 100%;
                max-width: 100px;
                min-width: 100px;
            }
            & a {
                font-family: 'Gilroy Bold';
                font-size: 21px;
                line-height: 21px;
                color: #8dc63f;
                transition: 0.3s ease;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                overflow: hidden;
                -webkit-box-orient: vertical;
                &:hover {color: #6ca954;}
            }
            & > span {
                width: 100%;
                max-width: 40px;
                min-width: 40px;
            }
            & span {
                font-family: 'Gilroy Regular';
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #4c4c4c; 
                & span {
                    font-size: 16px;
                    line-height: 16px;
                }
            }
            & p:not(.checkout-all) {
                display: block;
                width: 100%;
                max-width: 120px;
                text-align: right;
                min-width: 120px;
                font-family: 'Gilroy Bold';
                font-size: 21px;
                line-height: 21px;
                color: #4c4c4c;
                & span {
                    font-family: 'Gilroy Bold';
                    font-size: 14px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
            & .checkout-all {
                padding-bottom: 1px;
                font-family: 'Gilroy Bold';
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                color: #4c4c4c; 
            }
        }
    }
    &-edit {
        display: inline-block;
        padding-top: 15px;
        font-family: 'Gilroy Bold';
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #4c4c4c;
        color: #4c4c4c; 
        transition: 0.3s ease;
        &:hover {color: #8dc63f;}
    }
}
.cart {
    padding-bottom: 90px;
    @media (max-width: 992px) {padding-bottom: 50px;}
    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-without {
        display: none;
        width: 100%;
        padding-bottom: 20px;
        font-family: 'Gilroy Bold';
        font-size: 21px;
        line-height: 21px;
        text-align: center;
        color: #4c4c4c;
    }
    &-list {
        &.no-cnt {
            & .cart-navigation,
            & .cart-buttons {display: none;}
            & .cart-without {display: block;}
        }
    }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        max-width: 204px;
        min-width: 204px;
        padding: 10px;
        @media (max-width: 992px) {
            max-width: 170px;
            min-width: 170px;
        }
        @media (max-width: 767px)  {
            max-width: 204px;
            min-width: 0;
            margin-bottom: 15px;
            padding: 0;
        }
        & img {max-width: 100%;}
    }
    &-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        max-width: 145px;
        min-width: 145px;
        padding-right: 10px;
        @media (max-width: 992px) {
            max-width: 100px;
            min-width: 100px;
        }
    }
    & a.cart-name {
        font-family: 'Gilroy Bold';
        font-size: 21px;
        line-height: 21px;
        color: #8dc63f;
        transition: 0.3s ease;
        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 16px;
        }
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: 9px;
            font-size: 21px;
            line-height: 21px;
        }
        &:hover {color: #6ca954;}
    }
    &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        max-width: 462px;
        padding-right: 10px;
        @media (max-width: 767px) {
            padding: 0 0 20px;
        }
         & ul {
            padding: 0;
            margin: 0;
            list-style: none;
            & li {
                position: relative;
                padding-left: 13px;
                font-family: 'Gilroy Regular';
                font-size: 11px;
                line-height: 18px;
                color: #363636;
                &::before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0;
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #8dc63f;
                }
            }
         }
    }
    &-quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        min-width: 70px;
        max-width: 70px;
        @media (max-width: 767px) {
            justify-content: space-between;
            min-width: 0;
            max-width: 100%;
            margin-bottom: 15px;
        }
        & p {
            display: none;
            padding-right: 10px;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 16px;
            color: #4c4c4c;
            @media (max-width: 767px) {
                display: block;
                width: 100%;
            }
        }
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media (max-width: 767px) {justify-content: flex-end;}
        }
        & button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            background: none;
            outline: none;
            & img {width: 15px;}
        }
        & input {
            background: none !important;
            outline: none;
            width: 28px;
            margin: 0 5px;
            text-align: center;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 16px;
            color: #4c4c4c;
        }
    }
    &-price {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        height: 100%;
        width: 100%;
        min-width: 155px;
        max-width: 155px;
        padding: 0 10px;
        @media (max-width: 992px) {
            min-width: 115px;
            max-width: 115px;
        }
        @media (max-width: 767px) {
            min-width: 0;
            max-width: 100%;
            flex-flow: row nowrap;
            padding: 0;
        }
        & p {
            display: block;
            width: 100%;
            &.cart-price-old {
                max-width: 155px;
                font-family: 'Gilroy Black';
                font-size: 14px;
                line-height: 14px;
                text-align: right;
                text-decoration: line-through;
                color: #898989;
                @media (max-width: 992px) {
                    font-size: 11px;
                    line-height: 11px;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 14px;
                    max-width: initial;
                }
                & span {
                    font-size: 12px;
                    line-height: 12px;
                    text-transform: uppercase;
                    @media (max-width: 992px) {
                        font-size: 9px;
                        line-height: 9px;
                    }
                    @media (max-width: 767px) {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
            &.cart-price-new {
                max-width: 155px;
                font-family: 'Gilroy Bold';
                font-size: 21px;
                line-height: 21px;
                text-align: right;
                color: #4c4c4c;
                @media (max-width: 992px) {
                    font-size: 15px;
                    line-height: 15px;                
                }
                @media (max-width: 767px) {
                    font-size: 21px;
                    line-height: 21px;
                    max-width: initial;
                }
                & span {
                    font-size: 14px;
                    line-height: 14px;
                    text-transform: uppercase;
                    @media (max-width: 992px) {
                        font-size: 11px;
                        line-height: 11px;                
                    }
                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
    & .delete-cart-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 85px;
        max-width: 85px;
        padding: 0 40px 0 15px;
        @media (max-width: 992px) {
            min-width: 40px;
            max-width: 40px;
            padding: 0;        
        }
        @media (max-width: 767px) {
            position: absolute;
            top: -14px;
            right: -13px;
            min-width: 27px;
            max-width: 27px;
            height: 27px;
            background: #fff;
            border-radius: 50%;
        }
        & img {
            width: 100%;
            max-width: 27px;
            height: 27px;
        }
    }
    &-item {
        width: 100%;
        min-height: 172px;
        background-color: #f8f8f8;
        @media (max-width: 767px) {
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 35px 25px 30px;
            width: 100%;
            max-width: 298px;
            margin: 0 auto 25px;
            background: #fff;
            border: 1px solid #ebebeb;
            border-radius: 15px;
        }
        &:not(:last-child) {
            margin-bottom: 12px;
            @media (max-width: 767px) {margin: 5px 5px 30px 5px;}
        }
    }
    &-navigation {
        margin-bottom: 18px;
        @media (max-width: 767px) {display: none;}
        & .cart-name {
            margin-left: 204px;
            @media (max-width: 992px) {margin-left: 170px;}
        }
        & .cart-price {
            margin-right:  85px;
            @media (max-width: 992px) {margin-right: 40px;}
        }
        & .cart-price,
        & .cart-quantity {text-align: center;}
        & p {
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 14px;
            color: #acacac;
            @media (max-width: 992px) {
                font-size: 11px;
                line-height: 11px;
            }
        }
    }
    &-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 17px;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            padding-top: 0;
            width: 100%;
        }
        & div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 767px) {padding-bottom: 30px;}
        }
        & p {
            font-family: 'Gilroy Bold';
            font-size: 21px;
            line-height: 21px;
            text-transform: uppercase;
            color: #4c4c4c;
        }
        & .cart-all {
            margin-right: 40px;
            @media (max-width: 767px) {
                margin: 0 20px 0 0;
                text-align: center;
            }
        }
        & .cart-buttons-price {
            margin-right: 28px;
            @media (max-width: 767px) {
                margin: 0;
                text-align: center;
            }
            & span {
                font-size: 14px;
                line-height: 14px;
            }
        }
        & .btn {
            width: 100%;
            max-width: 174px;
            height: 45px;
            font-size: 16px;
            line-height: 45px;
        }
    }
    & form {
        @media (max-width: 767px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            flex-flow: row wrap;
        }
    }
}