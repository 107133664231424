.infobanner {
    padding-bottom: 72px;
    @media (max-width: 767px) {padding: 0 0 37px;}
    .wrapper {
        @media (max-width: 767px) {padding: 0;}
    }
    &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 767px) {flex-direction: column;}
        & a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            max-width: 367px;
            height: 162px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            @media (max-width: 1100px) {background-position: center right;}
            &:not(:last-child) {
                margin-right: 10px;
                @media (max-width: 767px) {margin-right: 0px;}
            }
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                transition: 0.3s ease;
            }
            &:hover::before {opacity: 1;}
            & > span {
                position: relative;
                left: 30px;
                z-index: 2;
                @media (max-width: 767px) {left: 22px;}
                & span {
                    display: block;
                    font-family: 'PF Din Text Cond Pro Bold';
                    text-transform: uppercase;
                    line-height: 27px;
                    color: #ffffff;
                    &.infobanner-title {
                        font-size: 36px;
                        line-height: 40px;
                        @media (max-width: 1170px) {
                            font-size: 28px;
                            line-height: 34px;
                        }
                        @media (max-width: 767px) {
                            font-size: 36px;
                            line-height: 40px;
                        }
                    }
                    &.infobanner-title-s {
                        font-size: 21px; 
                        @media (max-width: 1170px) {font-size: 18px; }
                        @media (max-width: 767px) {font-size: 21px; }
                    }
                    &.infobanner-title-m {
                        font-size: 26px; 
                        @media (max-width: 1170px) {font-size: 20px; }
                        @media (max-width: 767px) {font-size: 26px; }
                    }
                }
            }
        }
    }
}