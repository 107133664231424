.how {
    padding-bottom: 120px;
    @media (max-width: 992px) {padding-bottom: 40px;}
    .wrapper {
        max-width: 1360px;
        padding-left: 110px;
        @media (max-width: 1360px) {
            max-width: 1180px;
            padding-left: 20px;
        }
    
    }
    &-list {
        margin-bottom: 50px;
        & .bnf {
            p {
                max-width: 200px;
                @media (max-width: 700px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
    & p {
        font-family: 'Gilroy Regular';
        font-size: 16px;
        line-height: 21px;
        color: #737373;
        @media (max-width: 450px) {
            font-size: 12px;
            line-height: 17px;
        }
        &:not(:last-child) {margin-bottom: 39px;}
        & b {
            font-family: 'Gilroy Bold';
        }
        &.bold {
            &:not(:last-child) {margin-bottom: 20px;}
        }
    }
}