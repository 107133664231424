.thanks {
    .wrapper {
        display: flex;
        justify-content: center;
        padding: 190px 20px 211px;
        background-image: url(../images/fly2.png);
        background-size: 210px 196px;
        background-repeat: no-repeat;
        background-position: center left;
        @media (max-width: 1210px) {
            background-position: center left 20px;
        }
        @media (max-width: 767px) {
            padding: 40px 20px;
            background-position: top center;
            background-size: 140px 125px;
        }
        & div {
            max-width: 600px;
            margin: 0 0 0 244px;
            @media (max-width: 767px) {
                margin: 0;
                text-align: center;
            }
        }
    }
    & h1 {
        margin: 0 auto 10px;
        font-family: 'Gilroy Black';
        font-size: 41px;
        line-height: 41px;
        text-transform: uppercase;
        color: #464646;
    }
    & h4 {
        margin: 0 auto 10px;
        font-family: 'Gilroy Black';
        font-size: 21px;
        line-height: 21px;
        text-transform: uppercase;
        color: #464646;
    }
    & p {
        margin: 0 auto;
        font-family: 'Gilroy Regular';
        font-size: 18px;
        line-height: 21px;
        color: #464646;
    }
}