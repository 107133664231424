.infoblock {
    padding-top: 72px;
    padding-bottom: 94px;
    @media (max-width: 767px) {padding-bottom: 50px;}
    & .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & .logo {
            width: 100%;
            max-width: 190px;
            margin-right: 86px;
            & img {width: 100%;}
            @media (max-width: 992px)  {display: none;}
        }
    }
    &-cnt {
        & p {
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 21px;
            color: #898989;
            @media (max-width: 992px)  {
                font-size: 14px;
            }
        }
        & h5 {
            margin-bottom: 21px;
            font-family: 'Gilroy Bold';
            font-size: 18px;
            line-height: 21px;
            color: #8dc63f;
            @media (max-width: 992px)  {
                font-size: 16px;
                margin-bottom: 23px;            
            }
        }
        & .paragraph-item {
            &:not(:last-child) {
                margin-bottom: 54px;
                @media (max-width: 767px)  {
                    margin-bottom: 26px;
                }
            }
        }
    }
    &-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 50px;
        @media (max-width: 767px)  {
            flex-flow: row wrap;
            padding-bottom: 30px;
        }
    }
    &-column {
        width: 100%;
        max-width: 385px;
        @media (max-width: 767px)  {max-width: 100%;}
        &:not(:last-child) {
            padding-right: 15px;
            @media (max-width: 767px)  {margin-right: 0;}
        }
    }
}


.infoblock-popular {
    padding-top: 40px;
    @media (max-width: 767px) {padding-bottom: 27px;}
    h5 {
        display: inline-block;
        margin-bottom: 0;
        @media (max-width: 767px) {margin-bottom: 18px;}
    }
     & a {
        display: inline-block;
        padding: 10px;
        font-family: 'Gilroy Regular';
        font-size: 12px;
        line-height: 12px;
        color: #898989;
        border-radius: 7px;
        box-shadow: 0 0 3px lightgray;
        border: 1px solid #ebebeb;
        transition: 0.3s ease;
        &:hover {box-shadow: none;}
        &:not(:last-child) {
            margin-right: 10px;
            margin-bottom: 17px;
            @media (max-width: 767px) {margin: 0 3px 11px 0;}
        }
        @media (max-width: 767px) {padding: 13px 10px;}
     }
}