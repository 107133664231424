.reviews {
    padding-bottom: 105px;
}

.slider {
    & .owl-item {
        @media (max-width: 359px) {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }
    &-item {
        &:not(:last-child) {margin-bottom: 50px;}
    }
    &-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 32px;
        & p {
            margin-right: 15px;
            font-family: 'Gilroy Bold';
            font-size: 21px;
            line-height: 21px;
            text-transform: uppercase;
            color: #737373;
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 22px;
            
            }
        }
        & .slide-nav {
            display: flex;
            align-items: center;
            justify-content: center;
            & button {
                background-color: transparent;
                &:not(:last-child) {margin-right: 5px;}
            }
            & img,
            & svg {
                width: 24px;
                height: 24px;
                & path {
                    transition: 0.3s ease;
                    fill: #D7D4CB;
                }
                &:hover path {fill: #8dc63f;}
            }
        }
    }
    &-letters {
        & .slider {
            & a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 205px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    opacity: 0;
                    transition: 0.3s ease;
                }
                &:hover::before {opacity: 1;}
            }
        }
    }
    &-videos {
        & .slider {
            & iframe {
                width: 100%;
                max-width: 368px;
                min-height: 205px;
                @media (max-width: 1250px) {
                    min-height: 167px;
                }
                @media (max-width: 800px) {
                    min-height: 0;
                }
            }
        }
    }
}

.audio-item {
    position: relative;
    width: 100%;
    max-width: 206px;
    min-height: 212px;
    padding: 15px 10px 0 0;
    & img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 55px;
    }
    &-cnt {
        width: 100%;
        min-height: 195px;
        padding: 31px 45px 55px 33px;
        background-color: #f8f8f8;
        @media (max-width: 767px) {
            padding: 31px 45px 55px 15px;
        }
        & .audio-listen {
            position: absolute;
            bottom: 30px;
            left: 0;
            display: block;
            width: 100%;
            font-family: 'Gilroy Bold';
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            text-transform: uppercase;
            color: #737373;
            transition: 0.3s ease;
            &:hover {color: #8dc63f;}
        }
        & .audio-title {
            display: block;
            width: 100%;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 21px;
            color: #737373;
            transition: 0.3s ease;
            &:hover {color: #8dc63f;}
        }
    }
}

.rev-slider-wrap {
    position: relative;
    padding: 36px 15px 40px 35px;
    background-color: #f8f8f8;
    @media (max-width: 767px) {
        padding: 36px 15px 20px 15px;
    }
    & img {
        position: absolute;
        z-index: 1;
        top: -22px;
        right: -13px;
        width: 54px;
    }
    & .slider {width: 100%;}
    & .rev {
        position: relative;
        z-index: 2;
        width: 100%;
        &-title {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-flow: row wrap;
            padding-bottom: 15px;
            & p,
            & span {
                display: inline-block;
                font-size: 14px;
                line-height: 21px;
                color: #737373;
                @media (max-width: 767px) {
                    font-size: 12px;
                    line-height: 19px;
                }
            }
            & p {
                font-family: 'Gilroy Bold';
                text-transform: uppercase;
            }
            & span {
                font-family: 'Gilroy Regular';
                font-style: italic;
                & span {
                    padding-left: 8px;
                }
            }
        }
        & > p {
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 16px;
            color: #737373;
            @media (max-width: 767px) {
                font-size: 12px;
                line-height: 15px;
            }
            &:not(:last-child) {margin-bottom: 16px;}
        }
    }
}