.catalog {
    &-preview {
        padding-bottom: 33px;
        @media (max-width: 767px) {padding-bottom: 27px;}
    }
    &-head {
        .wrapper {
            @media (max-width: 767px) {padding: 0;}
        }
        .title {
            margin-bottom: 30px;
            @media (max-width: 767px) {margin-bottom: 35px;}
        }
    }
    &-list {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        @media (max-width: 1170px) {justify-content: space-around;}
        &.tab-cnt {display: none;}
        &.active {display: flex;}
    }
    &-item {
        position: relative;
        width: 100%;
        max-width: 298px;
        margin-bottom: 27px;
        padding: 8px 25px 30px;
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 15px;
        transition: 0.3s ease;
        overflow: hidden;
        @media (max-width: 767px) {margin-bottom: 14px;}
        &:hover {box-shadow: 0 0 20px #ebebeb;}
        &-img {
            display: block;
            width: 100%;
            height: 202px;
            margin-bottom: 9px;
        
            & img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-title {
            display: block;
            margin-bottom: 9px;
            font-family: 'Gilroy Bold';
            font-size: 21px;
            line-height: 21px;
            color: #8dc63f;
            text-transform: uppercase;
            transition: 0.3s ease;
            &:hover {color: #6ca954;}
        }
        & p {
            display: block;
            margin-bottom: 12px;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 16px;
            color: #262626;
        }
        &-price {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            & span {
                white-space: nowrap;
                font-family: 'Gilroy Bold';
                font-size: 19px;
                line-height: 19px;
                &.price-value {
                    font-size: 16px;
                    line-height: 16px;
                    text-transform: uppercase;
                }
                &.old {
                    margin-right: 16px;
                    color: #c2c2c2;
                    & span:not(.price-value) {text-decoration: line-through;}
                }
                &.new {
                    & span {
                        font-size: 16px;
                        line-height: 16px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        &-links {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & .btn {
                max-width: 139px;
                height: 45px;
                margin-right: 10px;
                font-size: 18px;
                line-height: 45px;
            }
        }
    }
    &-open {
        padding-bottom: 90px;
        @media (max-width: 767px)  {padding-bottom: 60px;}
        .title-s {
            margin-bottom: 24px;
        }
        .wrapper {
            .wrapper {padding: 0;}
        }
        & .catalog-list-wrap,
        & .catalog-no-items {
            width: 100%;
            max-width: 938px;
            padding-top: 50px;
            @media (max-width: 767px)  {
                padding-top: 35px;
            }
            & .catalog-list {
                justify-content: flex-start;
                width: 100%;
                @media (max-width: 1252px) {justify-items: center;}
                @media (max-width: 932px) {
                    flex-flow: row wrap;
                    justify-content: space-around;
                    align-items: flex-start;
                }
                & .catalog-item {
                    margin-right: 22px;
                    &:nth-child(3n) {margin-right: 0;}
                    @media (max-width: 1252px) {
                        &:nth-child(3n) {margin-right: 22px;}
                        &:nth-child(2n) {margin-right: 0px;}
                    }
                    @media (max-width: 932px) {
                        margin-right: 0;
                        &:nth-child(3n) {margin-right: 0;}
                        &:nth-child(2n) {margin-right: 0px;}
                    }
                }
            }
        }
        & .catalog-no-items {
            font-family: 'Gilroy Light';
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #292929;
        }
    }
    &-filter-section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: -11px;
        @media (max-width: 932px) {
            position: relative;
            flex-direction: column;
        }
        &.active {
            @media (max-width: 932px) {
                .catalog-filter-trigger {display: none;}
                & .catalog-filter {margin: 0 auto 30px;}
            }
        }
    }
    &-filter {
        margin-right: 10px;
        @media (max-width: 932px) {margin-right: 0;}
        &-trigger {
            display: none;
            align-items: center;
            justify-items: flex-start;
            width: 100%;
            max-width: 322px;
            height: 64px;
            margin: 0 auto 17px;
            padding: 5px 20px;
            font-family: 'Gilroy Black';
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            color: #4c4c4c;
            background-color: #f8f8f8;
            @media (max-width: 932px) {display: flex;}
            & img {
                width: 22px;
                height: 25px;
                margin-right: 10px;
            }
        }
        @media (max-width: 932px) {
            display: none;
            width: 100%;
            max-width: 322px;
            &.active {display: block;}
        }
    }
}

.filter {
    width: 100%;
    max-width: 265px;
    min-width: 265px;
    padding: 34px 10px 35px 24px;
    background-color: #f8f8f8;
    border-radius: 8px;
    @media (max-width: 932px) {
        max-width: 100%;
        padding: 34px 10px 35px 10px;
    }
    &-close {
        position: relative;
        left: 50%;
        display: none;
        width: auto;
        transform: translateX(-50%);
        margin: 0 auto 30px;
        font-family: 'Gilroy Black';
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #a9a9a9;
        color: #a9a9a9;
        @media (max-width: 932px) {display: inline-block;}
        @media (max-width: 359px) {
            font-size: 14px;
            line-height: 14px;
        }
    }
    &-sorting {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 932px) {
            &:not(.filter-sorting-mob) {display: none;}
        }
        &-mob {
            display: none;
            @media (max-width: 932px)  {
                display: flex;
                justify-content: center;
                width: 100%;
            }
            @media (max-width: 400px)  {justify-content: flex-start;}
        }
        & p,
        & a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 13px;
            padding-bottom: 5px;
            font-family: 'Gilroy Medium';
            font-size: 16px;
            line-height: 20px;
            color: #898989;
            & img {
                margin-left: 5px;
                width: 7px;
            }
            &.arrow-down {
                img {transform: rotate(180deg);}
            }
            @media (max-width: 932px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &-title {
        margin-bottom: 30px;
        font-family: 'Gilroy Black';
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #4c4c4c;
        @media (max-width: 932px)  {display: none;}
    }
    &-item {
        &:not(:last-child) {
            margin-bottom: 20px;
            @media (max-width: 932px) {
                margin-bottom: 21px;
                padding-bottom: 18px;
                border-bottom: 1px solid #ebebeb;
            }
        }
        &:not(.open){
            @media (max-width: 932px)  {
                & .filter-item-title:not(.filter-price-title) {margin-bottom: 0 !important;}
            }
        }
        &-info {
            pointer-events: fill;
            &-hidden {
                pointer-events: none;
                display: block;
                position: absolute;
                z-index: 5;
                top: 50%;
                left: calc(100% + 10px);
                transform: translateY(-50%) scale(0);
                width: 100%;
                max-width: 281px;
                min-width: 281px;
                padding: 12px 10px;
                overflow: hidden;
                font-family: 'Gilroy Medium';
                font-size: 14px;
                line-height: 21px;
                color: #c2c2c2;
                background-color: #ffffff;
                box-shadow: 0 0 3px lightgray;
                transition: 0.3s ease;
                & img {
                    pointer-events: fill;
                    display: none;
                    position: absolute;
                    cursor: pointer;
                    top: 5px;
                    right: 5px;
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    max-width: 20px;
                    min-height: 20px;
                    @media (max-width: 992px)  {display: block;}
                }
                @media (max-width: 992px) {
                    padding-top: 30px;
                    left: 0;
                    transform: translateY(0) scale(0);
                    top: 100%;
                }
                @media (max-width: 767px) {
                    padding-top: 30px;
                    left: 0;
                }
            }
            &.active .filter-item-info-hidden {
                transform: translateY(0) scale(1);
            }
            @media (min-width: 993px) {
                &:hover .filter-item-info-hidden{
                    transform: translateY(-50%) scale(1);
                }
            }
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 21px;
            @media (max-width: 932px) {
                padding-left: 13px;
                padding-right: 25px;
                margin-bottom: 16px;
            }
            @media (max-width: 359px) {padding-left: 0;}
            & img {
                width: 7px;
                height: 4px;
                display: none;
                transition: 0.1s ease;
                @media (max-width: 932px)  {display: block;}
            }
            &.active img {transform: rotate(180deg);}
            & p {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-start;            
                font-family: 'Gilroy Medium';
                font-size: 16px;
                line-height: 16px;
                color: #959595;
                @media (max-width: 359px) {
                    font-size: 14px;
                    line-height: 14px;
                }
                & span {
                    display: inline-block;
                    margin-right: 5px;
                }
                & svg {
                    width: 14px;
                    height: 14px;
                    & path {transition: 0.3s ease;}
                    &:hover path,
                    &.active path {fill: #8dc63f;}
                }
            }
        }
        &-cnt {
            display: flex;
            align-items: flex-start;
            flex-flow: row wrap;
            @media (max-width: 932px) {padding-left: 13px;}
            @media (max-width: 359px) {padding-left: 0;}
            &:not(.filter-price) {
                @media (max-width: 932px) {
                    display: none;
                }
            }
        }
        &.open {
            & .filter-item-cnt {
                @media (max-width: 932px) {
                    display: flex;
                }
            }
        }
        &.active .filter-item-cnt{
            display: flex;
        }
        &-buttons {
            display: flex;
            align-items: center;
            flex-flow: row nowrap !important;
            @media (max-width: 932px) {
                padding-top: 12px;
                padding-left: 18px;
            }
            & .btn {
                max-width: 103px;
                height: 33px;
                font-size: 14px;
                line-height: 33px;
                margin-right: 15px;
            }

            & .btn-reset {
                cursor: pointer;
                font-family: 'Gilroy Bold';
                font-size: 14px;
                line-height: 14px;
                text-transform: uppercase;
                color: #898989;
                transition: 0.3s ease;
                background: none !important;
                outline: none !important;
                &:hover {color: #8dc63f;}
            }
        }
    }
}


.checkbox-item {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    &:not(:last-child) {margin-bottom: 8px;}
    & input {display: none;}
    & .checker {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-right: 9px;
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        transition: 0.3s ease;
        & img {
            position: relative;
            z-index: 2;
            width: 9px;
            height: 6px;
            opacity: 0;
        }
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: -1px;
            left: -1px;
            display: block;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px solid #8dc63f;
            border-radius: 5px;
            transition: 0.3s ease;
            opacity: 0;
        }
    }
    & .checker-name {
        font-family: 'Gilroy Medium';
        font-size: 16px;
        line-height: 19px;
        color: #959595;
        @media (max-width: 359px) {
            font-size: 14px;
            line-height: 17px;
        }
    }
    & input:checked + .checker img,
    & input:checked + .checker:before {opacity: 1;}
    & input:checked ~ .checker-name {color: #8dc63f;}

    &-text {
        width: 60px;
        min-width: 60px;
        height: 35px;
        margin-right: 6px;
        &:nth-child(3n) {margin-right: 0;}
        @media (max-width: 932px) {
            &:nth-child(3n) {margin-right: 6px;}
            &:nth-child(4n) {margin-right: 0;}
        }
        & .checker {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            min-width: 60px;
            height: 35px;
            font-family: 'Gilroy Medium';
            font-size: 14px;
            line-height: 14px;
            color: #959595;
            transition: 0.3s ease;
            @media (max-width: 359px) {
                font-size: 14px;
                line-height: 14px;
            }
        }
        & input:checked + .checker {color: #8dc63f}
        &:hover  .checker {
            color: #8dc63f;
            border: 1px solid #8dc63f;
        }
    }
    &.disabled {
        opacity: 0.5;
        input:checked + .checker {color: #959595}
        &:hover  .checker {
            color: #959595;
            border: 1px solid #ebebeb;
        }    
        & input:checked + .checker img,
        & input:checked + .checker:before {opacity: 0;}
        & input:checked ~ .checker-name {color: #959595;}
    }
}

.range-slider {
    width: 100%;
    max-width: 195px;
    margin-bottom: 15px;
    .irs--round .irs-line {
        top: 3px;
        height: 2px;
        background-color: #cccccc;
        border-radius: 0;
    }
    .irs--round .irs-bar {
        top: 3px;
        height: 2px;
        background-color: #8dc63f;
    }
    .irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {background-color: #8dc63f;}
    .irs--round .irs-handle {
        top: -1px;
        width: 10px;
        height: 10px;
        border: none;
        background-color: #8dc63f;
        border-radius: 50%;
        box-shadow: none;
    }
    .irs-min, .irs-max, .irs-from, .irs-single, .irs-to {display: none !important;}
    .irs--round {
        height: 10px;
    }
    &-controls {
        & input {
            width: 100%;
            max-width: 95px;
            height: 35px;
            background: #ffffff;
            font-family: 'Gilroy medium';
            font-size: 14px;
            line-height: 35px;
            color: #c2c2c2;
            border-radius: 5px;
            padding: 2px 2px 2px 6px;
            border: 1px solid #ebebeb;
            &:not(:last-child) {margin-right: 8px;} 
        }
    }
}

.link-text {
    font-family: 'Gilroy Bold';
    font-size: 16px;
    line-height: 20px;
    color: #8dc63f;
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s ease;
    &:hover {color: #6ca954;}
}

.stickers {
    position: absolute;
    top: 35px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & .sticker {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 66px;
        height: 36px;
        padding: 0 5px;
        font-family: 'Gilroy SemiBold';
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        text-transform: uppercase;
        &:not(:last-child) {margin-bottom: 6px;}
        &-s {
            font-size: 11px;
            line-height: 11px;
        }
        &-m {
            font-size: 12px;
            line-height: 12px;
        }
        &.sticker-green {background-color: #8dc63f;}
        &.sticker-orange {background-color: #f7941c;}
    }
}

.item-triggers {
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .item-trigger {
        cursor: pointer;
        &:not(:last-child) {margin-right: 9px;}
        & path {
            fill: #DEDEDE;
            transition: 0.3s ease-out;
        }
        // &:hover path,
        &.active path {fill: #8dc63f;}
    }
}

.circle-list {
    padding: 0 0 19px;
    margin: 0;
    list-style: none;
    & li {
        position: relative;
        padding-left: 12px;
        font-family: 'Gilroy SemiBold';
        font-size: 11px;
        line-height: 18px;
        color: #363636;
        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #8dc63f;
        }
    }
}