body {
    &.hidden {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        @media (min-width: 1050px) {
            padding-right: 17px; 
        }
    }
}


// fixed header  and footer

html, body {height: 100%;}

.main-wrapper {	
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
.main {
    flex: 1 0 auto;
    @media (max-width: 992px) {padding-top: 30px;}
}
footer {flex: 0 0 auto;}

.wrapper {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
    &.wrapper-m {max-width: 1300px;}
    &.wrapper-ml {max-width: 1460px;}
    &.wrapper-l {max-width: 1810px;}
    &.wrapper-big {max-width: 1920px;}
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 196px;
    height: 51px;
    font-family: 'Gilroy Bold';
    font-size: 16px;
    line-height: 51px;
    text-align: center;
    color: #ffffff;
    background-color: #8dc63f;
    text-transform: uppercase;
    border-radius: 25px;
    transition: 0.3s ease;
    @media (max-width: 767px) {
        max-width: 127px;
        height: 33px;
        font-size: 10px;
        line-height: 33px;
    }
}

.btn:hover {background-color: #6ca954;}

.title {
    display: block;
    margin-bottom: 80px;
    font-family: 'Gilroy Black';
    font-size: 45px;
    line-height: 55px;
    text-transform: uppercase;
    color: #4c4c4c;
    &-s {
        font-size: 41px;
        line-height: 56px;
        margin-bottom: 39px;
        @media (max-width: 992px) {
            font-size: 32px;
            line-height: 42px;
        }
    }
    @media (max-width: 1150px) {margin-bottom: 50px;}
    @media (max-width: 992px) {
        font-size: 32px;
        line-height: 42px;
    }
}

.tab-cnt {display: none;}

.tab-cnt.active {display: block;}

.tabs-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 69px;
    @media (max-width: 992px) {padding-bottom: 35px;}
    & a {
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        font-family: 'Gilroy Bold';
        font-size: 21px;
        line-height: 21px;
        text-transform: uppercase;
        color: #4c4c4c;
        transition: 0.3s ease;
        @media (max-width: 992px) {
            font-size: 15px;
            line-height: 15px;
        }
        @media (max-width: 767px) {padding-bottom: 7px;}
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 4px;
            background-color: #8dc63f;
            opacity: 0;
            transition: 0.3s ease;
            @media (max-width: 767px) {height: 3px;}
        }
        &:not(:last-child) {
            margin-right: 32px;
            @media (max-width: 992px) {margin-right: 15px;}
        }
        &:hover,
        &.active {
            color: #8dc63f;
            &::before {opacity: 1;}
        }
    }
}


.top-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 6;
    display: none;
    @media (max-width: 992px) {display: none !important;}
    & svg {
        background: #ffffff;
        border-radius: 50%;
    }
    & path {
        fill: #8dc63f;
        transition: 0.3s ease;
    }
    &:hover path {fill: #6ca954;}
}

.fix-triggers {
    position: fixed;
    top: 175px;
    right: 0;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 20px 25px;
    background-color: #f8f8f8;
    box-shadow: 0 0 15px #eaeaea;
    @media (max-width: 1170px) {padding: 15px;}
    @media (max-width: 767px) {display: none;}
    & svg {
        & path {
            fill: #8dc63f;
            transition: 0.3s ease;
        }
    }
}

.fix-trigger {
    position: relative;
    display: block;
    width: 32px;
    height: 31px;
    &:not(:last-child) {margin-bottom: 30px;}
    &:hover svg path {fill: #afafaf;}
    & span {
        position: absolute;
        top: -6px;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-family: 'Gilroy Regular';
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #8dc63f;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0 0 5px lightgray;
    }
}

.caller {
    position: fixed;
    left: 75px;
    bottom: 20px;
    z-index: 7;
    cursor: pointer;
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85px;
        height: 85px;
        background-color: #8dc63f;
        border-radius: 50%;
        transition: 0.3s ease;
        &-icon {
            transition: 0.3s ease;
            overflow: hidden;
            &.caller-close {
                width: 0;
                height: 0;
                opacity: 0;
            }
            &.caller-open {
                width: 52px;
                height: 46px;
                opacity: 1;
            }
        }
    }
    &-hidden {
        position: absolute;
        left: 50%;
        bottom: calc(100%);
        transform: translateX(-50%);
        & a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0;
            height: 0;
            background-color: #8dc63f;
            border-radius: 50%;
            transition: 0.3s ease;
            overflow: hidden;
            opacity: 0;
            margin-bottom: 10px;
            &:hover {background-color: #6ca954;}
            &.caller-phone img {
                width: 26px;
                height: 25px;
            }
            &.caller-message img {
                width: 29px;
                height: 27px;
            }
        }
    }
    @media (max-width: 992px) {
        z-index: 5;
        left: 15px;
        & .caller-img {
            width: 50px;
            height: 50px;
            & .caller-img-icon.caller-open {
                width: 28px;
                height: 24px;
            }

        }
    }
    &.open {
        & .caller-img {background-color: #d7d7d7;}
        & .caller-img-icon {
            &.caller-close {
                width: 27px;
                height: 27px;
                opacity: 1;
            }
            &.caller-open {
                width: 0;
                height: 0;
                opacity: 0;
            }
        }
        & .caller-hidden {
            & a {
                width: 65px;
                height: 65px;
                opacity: 1;
            }
        }
    }
    @media (max-width: 992px) {
        &.open .caller-hidden a {
            width: 35px;
            height: 35px;
            &.caller-phone img {
                width: 17px;
                height: 17px;
            }
            &.caller-message  img {
                width: 18px;
                height: 15px;
            }
        }
    }
}



.popup-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
    padding: 15px;
	z-index: 10;
    display: none;
	transition: 0.5s ease;
}

.popup-layout {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 6;
	display: flex;
	align-items: center;
	justify-content: center;
    @media (max-width: 440px) {
        align-items: flex-start;
        padding: 20px;
    }
	background: rgba(0, 0, 0, 0.4);
}

.popup {
  position: relative;
  width: 100%;
  max-width: 393px;
  padding: 45px 30px;
  background-color: #ffffff;
  @media (max-width: 440px) {
      padding: 50px 15px;
  }
}

.popup-title {
    margin-bottom: 43px;
    font-family: 'Gilroy Bold';
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    color: #4c4c4c;
}

.field {
    display: block;
    width: 100%;
    height: 34px;
    padding: 5px 13px 9px;
    font-family: 'Gilroy Regular';
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #999999;
    border-radius: 0 !important;
    border-bottom: 1px solid #E1E1E1;
    background-color: transparent;
    transition: 0.3s ease;
    &.error {border-bottom: 1px solid red;}
    &:not(:last-child) {margin-bottom: 17px;}
    &:focus {border-bottom: 1px solid #8dc63f;}
}

.popup .btn {
    max-width: 174px;
    margin-top: 33px;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
}

.popup-close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
	width: 100%;
	max-width: 27px;
}

.popup-close img {width: 100%;}

.more-btn {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 14px;
    font-family: 'Gilroy Bold';
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #8dc63f;
    @media (max-width: 767px) {display: flex;}
    & img {
        width: 18px;
        height: 20px;
        margin-left: 10px;
        transition: 0.3s ease;
    }
    &.active {
        & img {transform: rotate(180deg);}
    }
}

.more-cnt .more-cnt-item:nth-child(n+5) {
    @media (max-width: 767px) {display: none;}
}

.more-cnt.more-active .more-cnt-item:nth-child(n+5) {
    @media (max-width: 767px) {display: block;}
}

.article {
    position: relative;
    min-height: 442px;
    h1 {
        display: block;
        margin-bottom: 29px;
        font-family: 'Gilroy Black';
        font-size: 40px;
        line-height: 51px;
        text-transform: uppercase;
        color: #4c4c4c;
        @media (max-width: 992px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    & p,
    & a,
    & h5 {
        font-family: 'Gilroy Regular';
        font-size: 16px;
        line-height: 26px;
        color: #737373;
        transition: 0.3s ease;
        @media (max-width: 992px) {
            font-size: 12px;
            line-height: 22px;
        }
        & b {
            font-family: 'Gilroy Bold';
            font-size: 21px;
            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
        &:not(:last-child) {margin-bottom: 26px;}
    }
    & a:hover {color: #8dc63f;}
    & iframe {
        display: block;
        width: 100%;
        max-width: 932px;
        height: auto;
        min-height: 400px;
        margin: 25px auto 26px;    
        @media (max-width: 767px) {
            min-height: 0;
            max-width: 400px;
        }
        @media (max-width: 400px) {
            min-height: 0px;
        }
    }
    & img {
        width: 100%;
        max-width: 440px;
        margin-bottom: 12px;
        &[align="right"] {
            margin-left: 22px;
        }
        &[align="left"] {
            margin-right: 22px;
        }
        @media (max-width: 992px) {max-width: 310px;}
        @media (max-width: 767px) {
            margin: 0 auto 35px !important;
            float: none;
        }
    }
    & div a {
        display: block;
        text-align: right;
        font-style: italic;
        transition: 0.3s ease;
        &:hover {color: #8dc63f;}
    }
    &-share {
        position: absolute;
        right: -55px;
        top: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 1250px) {
            position: static;
            flex-flow: row wrap;
            padding-top: 30px;
        }
        & p {
            display: none;
            width: 100%;
            font-family: 'Gilroy Bold';
            font-size: 16px;
            line-height: 16px;
            color: #737373;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 10px !important;
            @media (max-width: 1250px) {display: block;}
        }
        &-trigger,
        &-cnt a{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            transition: 0.1s linear;
            &:not(:last-child) {
                margin-bottom: 5px;
                @media (max-width: 1250px) {margin: 0 11px 0 0;}
            }
            & img {
                width: 25px;
                height: 27px;
                margin: 0 !important;
            }
        }
        
        &-cnt {
            height: 0;
            overflow: hidden;
            @media (max-width: 1250px) {
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            a {
                transform: scale(0);
                @media (max-width: 1250px) {transform: scale(1);}
                overflow: hidden;
                & img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &:hover .article-share-cnt {
            height: auto;
            a {
                transform: scale(1);
            }
        }
    }
    & h5 {
        &:not(:last-child) {margin-bottom: 5px;}
    }
    & ul {
        padding: 7px 0 26px;
        margin: 0;
        list-style: none;
        & li {
            position: relative;
            padding-left: 21px;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 26px;
            color: #737373;
            @media (max-width: 992px) {
                font-size: 12px;
                line-height: 22px;
            }
            & b {
                font-family: 'Gilroy Bold';
                font-size: 21px;
                @media (max-width: 992px) {
                    font-size: 16px;
                }
            }
            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid #8dc63f;
            }
        }
    }
    & ol {
        padding: 7px 0 26px;
        margin: 0;
        list-style: none;
        counter-reset: my-awesome-counter;
        & > li {
            position: relative;
            padding-left: 21px;
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 26px;
            color: #737373;
            counter-increment: my-awesome-counter;
            @media (max-width: 992px) {
                font-size: 12px;
                line-height: 22px;
            }
            & b {
                font-family: 'Gilroy Bold';
                font-size: 21px;
                @media (max-width: 992px) {
                    font-size: 16px;
                }
            }
            &::before {
                content: counter(my-awesome-counter) ".";
                position: absolute;
                top: 0px;
                left: 0;
                display: block;
                font-family: 'Gilroy Regular';
                font-size: 16px;
                line-height: 26px;
                color: #8dc63f;
            }
        }
    }
}


// select

/* The container must be positioned relative: */

.custom-select {
    position: relative;
    padding: 0 12px;
    font-family: 'Gilroy Medium';
    font-size: 16px;
    line-height: 16px;
    color: #898989;
    cursor: pointer;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
    & select {display: none;}  
    .select-selected {
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            right: 0;
            display: block;
            width: 7px;
            height: 4px;
            margin-left: 2px;
            background-image: url(../images/arrow-btm2.svg);
            background-repeat: no-repeat;
            background-size: contain;
            transition: 0.15s ease;
        }
        &.select-arrow-active {
            &::after {transform: rotate(180deg);}
        }
    }
    .select-items div,.select-selected {
        cursor: pointer;
        padding-bottom: 5px;
        font-family: 'Gilroy Medium';
        font-size: 16px;
        line-height: 20px;
        color: #898989;
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .select-selected {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        padding-right: 20px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 20px;
            height: 22px;
            background-color: #fff;
        }
    }
    .select-items {
        position: absolute;
        top: 100%;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        z-index: 5;
        min-width: 170px;
        padding: 10px;
        background: #fff;
        border-radius: 0;
        border: 1px solid #ebebeb;
        box-shadow: none;
    }
    .select-hide {
        display: none;
    }
    .select-items div:hover, .same-as-selected {color: #8dc63f;}
    &:last-child {
        .select-items {
            left: auto;
            right: 0;
            transform: translateX(0);
        }
    }
}
  

  
// pagination

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 42px;
        @media (max-width: 767px) {justify-content: center;}
    }

    & a  {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-family: 'Gilroy Regular';
        font-size: 11px;
        line-height: 11px;
        color: #acacac;
        transition: 0.3s ease;
        &:not(:last-child) {margin-right: 7px;}
        &:not(.pagination-arrow) {
            border: 1px solid #ebebeb;
            &:hover {
                color: #8dc63f;
                border: 1px solid #8dc63f;
            }
        }
    }
    &-arrow {
        & img {width: 100%;}
    }
}