.banner {
    padding: 20px 0 14px;
    @media (max-width: 992px) {padding: 0 0 23px;}
    & .wrapper {
        padding-left: 162px;
        @media (max-width: 1470px) {
            max-width: 1180px;
            padding-left: 20px;
        }
    }
    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
        }
        &-cnt {
            width: 100%;
            max-width: 580px;
            padding-right: 15px;
            @media (max-width: 600px) {
                padding: 0;
                order: 2;
            }
        }
    }
    &-img {
        width: 100%;
        max-width: 515px;
        height: 508px;
        @media (max-width: 1470px) {
            max-width: 360px;
            height: auto;
        }
        @media (max-width: 600px) {
            order: 1;
            margin: 0 auto 15px;
            max-width: 260px;
        }
        & img {width: 100%;display: none;}
    }
    &-title {
        display: block;
        margin-bottom: 20px;
        font-family: 'Gilroy Black';
        font-size: 39px;
        line-height: 53px;
        color: #464646;
        @media (max-width: 1150px) {
            font-size: 32px;
            line-height: 42px;
        }
        @media (max-width: 992px) {
            font-size: 21px;
            line-height: 26px;
        }
    }
}

.banner-slider .owl-dots {
    position: absolute;
    bottom: 15px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 767px) {display: none;}
    & .owl-dot {
        display: block;
        width: 100%;
        height: 2px;
        max-width: 59px;
        background-color: #ebebeb !important;
        transition: 0.3s ease;
        &:not(:last-child) {margin-right: 6px;}
        & span {display: none;}
        &.active {background-color: #8dc63f !important;}
    }
}
