// Open sans

@font-face {
    font-family: 'Open Sans LightItalic';
    src: url('./fonts/opensans/OpenSans-LightItalic.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('./fonts/opensans/OpenSans-SemiBold.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans ExtraBoldItalic';
    src: url('./fonts/opensans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('./fonts/opensans/OpenSans-Bold.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans ExtraBold';
    src: url('./fonts/opensans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans BoldItalic';
    src: url('./fonts/opensans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('./fonts/opensans/OpenSans-Light.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url('./fonts/opensans/OpenSans-Italic.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans SemiBoldItalic';
    src: url('./fonts/opensans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('./fonts/opensans/OpenSans-Regular.woff2') format('woff2'),
        url('./fonts/opensans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



// Gilroy

@font-face {
    font-family: 'Gilroy SemiBold';
    src: url('./fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('./fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('./fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('./fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy ExtraBoldItalic';
    src: url('./fonts/gilroy/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy Light';
    src: url('./fonts/gilroy/Gilroy-Light.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Thin';
    src: url('./fonts/gilroy/Gilroy-Thin.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Black';
    src: url('./fonts/gilroy/Gilroy-Black.woff2') format('woff2'),
        url('./fonts/gilroy/Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}


// pfd


@font-face {
    font-family: 'PF Din Text Cond Pro Bold';
    src: url('./fonts/pfd/PFDinTextCondPro-Bold.woff2') format('woff2'),
        url('./fonts/pfd/PFDinTextCondPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

