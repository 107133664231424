.prices {
    padding-bottom: 110px;
    @media (max-width: 992px) {padding-bottom: 40px;}
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-flow: row wrap;
            justify-content: space-around;
        }
    }
    & .price-item {
        display: block;
        width: 100%;
        max-width: 180px;
        @media (max-width: 860px) {
            max-width: 150px;
            margin: 0 0 32px;
        }
        @media (max-width: 359px) {max-width: 135px;}
        &:hover .price-name {color: #8dc63f;}
        &:hover .price-img img {transform: translateY(-26px);}
    }
    & .price-img {
        position: relative;
        display: block;
        width: 100%;
        max-width: 156px;
        height: 148px;
        margin: 0 auto 35px;
        background-color: #f8f8f8;
        @media (max-width: 860px) {
            max-width: 119px;
            height: 113px;
            margin: 0 0 32px;
        }
        & img {
            position: absolute;
            bottom: -15px;
            right: 15px;
            transition: 0.2s linear;
            @media (max-width: 860px) {max-height: 120px;}
        }
    }
    & .price-name {
        display: block;
        font-family: 'Gilroy Regular';
        font-size: 21px;
        line-height: 21px;
        text-align: center;
        color: #4c4c4c;
        transition: 0.3s ease;
        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 16px;
        }
        @media (max-width: 860px) {text-align: left;}
    }
}