.contacts {
    .wrapper-big {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
    }
    &-row {
        position: relative;
        width: 100%;
        max-width: 1574px;
        padding-left: 74px;
        @media (max-width: 1600px) {
            padding-left: 0;
            max-width: 100%;
        }
    }
    &-map {
        width: 100%;
        height: 600px;
        max-width: 1532px;
        @media (max-width: 1600px) {max-width: 100%;}
        @media (max-width: 767px)  {height: 350px;}
        & iframe {
            width: 100%;
            height: 600px;
            @media (max-width: 767px)  {height: 350px;}
        }
    }
    &-cnt {
        position: absolute;
        top: 44px;
        left: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 563px;
        padding: 46px 48px 46px;
        background-color: #f8f8f8;
        box-shadow: 0 0 5px lightgrey;
        @media (max-width: 1600px) {
           left: 20px;
        }
        @media (max-width: 767px) {
            position: static;
            max-width: 100%;
            box-shadow: none;
            padding: 0 20px 40px;
            background: transparent;
            justify-content: flex-start;
        }
        @media (max-width: 450px) {flex-flow: row wrap;}
    }
    &-column {
        width: 100%;
        max-width: 228px;
        &:not(:last-child) {
            @media (max-width: 767px)  {margin-right: 10px;}
            @media (max-width: 450px)  {margin: 0 0 30px;}
        }
        
        @media (max-width: 450px) {max-width: 100%;}
    }
    &-item {
        &:not(:last-child) { margin-bottom: 30px;}
        &-title {
            margin-bottom: 8px;
            font-family: 'Gilroy Black';
            font-size: 21px;
            line-height: 32px;
            text-transform: uppercase;
            color: #4c4c4c;
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        & p,
        & a {
            display: block;
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 21px;
            color: #4c4c4c;
            transition: 0.3s ease;
        }
        & a:hover {color: #8dc63f;}
        & .socials-links {padding-top: 20px;}
    }
}