.about {
    padding-bottom: 110px;
    overflow: hidden;
    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 41px;
        @media (max-width: 767px) {flex-direction: column;}
        &-3 {
            padding-top: 64px;
            margin-bottom: 57px;
            @media (max-width: 767px)  {
                padding-top: 0;
                margin-bottom: 40px;
            }
        }
    }
    &-img {
        position: relative;
        width: 100%;
        max-width: 375px;
        @media (max-width: 767px) {
            order: 2;
            margin: 0 auto !important;
        }
        & img:not(.about-img-bg) {
            width: 100%;
            position: relative;
            z-index: 2;
        }
        &-1 {
            margin-right: 115px;
            @media (max-width: 992px) {margin-right: 20px;}
            & .about-img-bg {
                position: absolute;
                top: 17px;
                left: -158px;
                z-index: 1;
                @media (max-width: 992px) {display: none;}
            }
        }
        &-2 {
            margin-left: 45px;
            @media (max-width: 992px) {margin-left: 20px;}
            & .about-img-bg {
                position: absolute;
                top: 23px;
                right: -86px;
                z-index: 1;
                @media (max-width: 1100px) {top: -30px;}
                @media (max-width: 992px) {display: none;}
            }
        }
    }
    &-text {
        width: 100%;
        max-width: 720px;
        @media (max-width: 767px) {
            order: 1;
            margin: 0 0 30px;
        }
        & p,
        & a {
            font-family: 'Gilroy Regular';
            font-size: 16px;
            line-height: 21px;
            color: #737373;
            transition: 0.3s ease;
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 19px;
            }
        }
        & a {
            color: #8dc63f;
            &:hover {color: #6ca954;}
        }
        & p:not(:last-child) {margin-bottom: 21px;}
    }
}