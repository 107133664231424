.header-triggers {
    padding: 25px 0;
    background-color: #F8F8F8;
    @media (max-width: 992px) {padding: 19px 0 0;}
    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: 993px) {
            & .fix-trigger {
                display: none;
            }
        }
        @media (max-width: 992px) {
            flex-flow: row wrap;
            margin: 0 auto 36px;
            max-width: 360px;
            & .fix-trigger {margin: 0 10px 0 0;}
            & .search-field  {max-width: 188px;}
            & .call-triggers {
                margin-top: 30px;
                & a:not(.callback-trigger) {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
    }
}

.header-nav-wrap {
    background-color: #F8F8F8;
    @media (max-width: 992px) {display: none;}
}

.search-field {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 244px;
    height: 40px;
    background-color: #ffffff;
    & input {
        width: 100%;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        font-family: 'Gilroy Regular';
        font-size: 15px;
        line-height: 40px;
        color: #b1b1b1;
        &::-webkit-input-placeholder {
            text-transform: uppercase;
            color: #b1b1b1;
        }
        &::-moz-placeholder  {
            text-transform: uppercase;
            color: #b1b1b1;
        }
        &:-ms-input-placeholder  {
            text-transform: uppercase;
            color: #b1b1b1;
        }
        &:-moz-placeholder  {
            text-transform: uppercase;
            color: #b1b1b1;
        }
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        height: 40px;
        background: transparent;
        & img {
            width: 20px;
            height: 21px;
        }
    }
}

.call-triggers {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 359px) {
        flex-direction: column;
        width: 100%;
    }
    & a:not(.callback-trigger) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
        font-family: 'Gilroy Regular';
        font-size: 18px;
        line-height: 18px;
        color: #636363;
        @media (max-width: 359px) {margin: 0 auto 15px;}
        & img {
            width: 34px;
            height: 34px;
            margin-right: 10px;
        }
    }
    & .callback-trigger {
        display: inline-block;
        margin-top: 1px;
        padding-bottom: 1px;
        font-family: 'Gilroy Bold';
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #8dc63f;
        border-bottom: 1px solid #8dc63f;
        transition: 0.3s ease;
        &:hover {border-bottom: 1px solid transparent;}
    }
}

.header-navigation {
    padding: 5px 0 34px;
    background-color: #ffffff;
    // transition: 0.3s linear;
    @media (max-width: 992px) {
        max-width: 320px;
        margin: 0 auto;
        padding: 0 0 90px;
        background-color: #F8F8F8;
    }
    .wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media (max-width: 992px) {
            max-width: 360px;
            margin: 0 auto;
        }
    }
    & .logo {
        display: block;
        width: 100%;
        max-width: 190px;
        @media (max-width: 992px) {display: none; }
        & img {width: 100%;}
    }
}

nav {
    position: relative;
    @media (max-width: 992px) {width: 100%;}
    & ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        @media (max-width: 992px)  {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .menu-hidden-trigger-hidden {display: none;}
        }
        & li {
            &:not(:last-child) {
                margin-right: 31px;
                @media (max-width: 1170px) {
                    margin-right: 22px;
                }
                @media (max-width: 992px) {
                    margin: 0 0 20px;
                }
            }
            & a {
                position: relative;
                font-family: 'Gilroy Bold';
                font-size: 18px;
                line-height: 18px;
                color: #464646;
                text-transform: uppercase;
                transition: 0.3s ease;
                @media (max-width: 1170px) {
                    font-size: 15px;
                    line-height: 15px;
                }
                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 18px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    top: -46px;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background-color: #8dc63f;
                    transition: 0.3s ease;
                    opacity: 0;
                }
                &:hover {
                    @media (min-width: 993px) {
                        color: #8dc63f;
                        &::before {opacity: 1;}
                    }
                }
            }
            
        }
    }
}
.menu-hidden {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 55px 50px;
    background-color: #ffffff;
    z-index: 4;
    box-shadow: 0 0 9px 1px #E7E7E7;
    @media (max-width: 992px)  {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        &-trigger {
            margin: 0 !important;
            width: 100%;
        }
    }
    &-wrapper {
        position: absolute;
        z-index: 7;
        top: 100%;
        left: 0;
        display: none;
        width: 100%;
        padding-top: 30px;
        @media (max-width: 992px)  {
            position: static;
            display: block;
            padding: 0;
        }
    }
    &-column {
        &:not(:last-child) {margin-right: 40px;}
        @media (max-width: 992px) {
            width: 100%;
            margin: 0 0 7px !important;
        }
        & ul {
            display: block;
            &.open {
                & li:not(.menu-hidden-title) {display: block;}
                & .menu-hidden-title{
                    & a {color: #8dc63f;}
                    & svg {
                        transform: rotate(180deg);
                        & path {fill: #8dc63f;}
                    } 
                }
            }
            li {
                @media (max-width: 992px) {
                    margin: 0 0 20px !important;
                    &:not(.menu-hidden-title) {display: none;}
                }
                & a {
                    display: block;
                    margin-bottom: 13px;
                    font-family: 'Gilroy Regular';
                    font-size: 14px;
                    line-height: 14px;
                    color: #4c4c4c;
                    text-transform: none;
                    transition: 0.3s ease;
                    &::before {display: none;}
                    &:hover{color: #8dc63f;}
                }
                &.menu-hidden-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & svg {
                        display: none;
                        width: 7px;
                        height: 4px;
                        & path {transition: 0.3s ease;}
                        @media (max-width: 992px) {display: block;}
                    }
                    @media (max-width: 992px) {margin-bottom: 10px;}
                    a {
                        margin-bottom: 10px;
                        font-family: 'Gilroy Black';
                        font-size: 21px;
                        line-height: 21px;
                        text-transform: uppercase;
                        transition: 0.3s ease;
                        @media (max-width: 992px) {
                            font-family: 'Gilroy Bold';
                            margin-bottom: 0;
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

.header-mob {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px 25px;
    background-color: #F8F8F8;
    @media (min-width: 993px) {display: none;}
    & .logo {
        width: 100%;
        max-width: 161px;
    }
    &-triggers {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        & .cart-trigger {
            margin: 0 53px 0 0;
            @media (max-width: 359px) {margin: 0 25px 0 0;}
        }
        & .nav-trigger {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 39px;
            height: 39px;
            & img {
                &.nav-close {
                    width: 39px;
                    height: 39px;
                }
                &.nav-open {
                    display: none;
                    width: 27px;
                    height: 27px;
                }
            }
        }
    }
}

header {
    &.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: 7;
        background-color: #ebebeb;
        .header-nav-wrap {
            display: block;
            padding-bottom: 80px;
            overflow: scroll;
            height: calc(100vh - 90px);
        }
        & .header-mob {
            &-triggers {
                & .nav-trigger {
                    & img {
                        &.nav-close {display: none;}
                        &.nav-open {display: block;}
                    }
                }
            }
        }
    }
    @media (min-width: 993px) {
        &.fixed {
            & .header-navigation {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 7;
                box-shadow: 0 0 10px lightgray;
            }
        }
        &.out {
            & .header-navigation {
                transform: translateY(calc(-100% - 15px));
            }
        }
    }
    @media (max-width: 992px)  {
        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 7;
            box-shadow: 0 0 10px lightgray;
        }
        &.out {
            transform: translateY(calc(-100% - 15px));
        }
    }
} 