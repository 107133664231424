.breadcrumbs {
    padding: 11px 0 39px;
    @media (max-width: 992px) {
        padding: 0 0 20px; 
    }
    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & a,
        & span {
            font-family: 'Gilroy Regular';
            font-size: 14px;
            line-height: 14px;
            color: #acacac;
            transition: 0.3s ease;
            &:not(.breadcrumbs-slash) {
                display: -webkit-box;
                height: 14px;
                -webkit-line-clamp: 1;
                overflow: hidden;
                -webkit-box-orient: vertical;
            }
            &.breadcrumbs-slash {
                display: inline-block;
                margin: 0 3px;
            }
        }
        & a:hover {color: #8dc63f;}
    }
}